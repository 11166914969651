import {Auth} from "@aws-amplify/auth";

export async function getAccessTokenAndFederateUser() {
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    return [authenticatedUser.signInUserSession.idToken.jwtToken, authenticatedUser.username];
}

export async function getInputContext() {
    const [token, username] = await getAccessTokenAndFederateUser();
    return {
        headers: {
            "Authorization": token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": ["https://prod.compliance-dashboard.tax.amazon.dev" , "https://d2jlise6kfqzy4.cloudfront.net", "https://localhost:4321"],
            Accept: 'application/json'
        },
        body: {
        "userAlias" : username,
                  "requestType": "",
                  "requestString": ""
        }
    };
}
